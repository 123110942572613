body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Arial", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e3e3f !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Header */
.banner {
  background: #ffc300 !important;
  padding: 5px 0px;

  img {
    max-width: 100%;
  }
}

.bannerText {
  height: 100%;
}

.menuHorizontal {
  display: inline-block !important;
}

.menuItemSelected {
  color: #3e3e3f !important;
  font-weight: bold !important;
  background: #ffc300 !important;
}

/* Footer */
.footer {
  background: #3e3e3f !important;
  color: #ffffff;
  padding: 10px 0px;
}